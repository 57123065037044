import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
const dateData = [
    { week: 'Week 1', dateRange: '23-09-2024 to 29-09-2024' },
    { week: 'Week 2', dateRange: '30-09-2024 to 06-10-2024' },
    { week: 'Week 3', dateRange: '07-10-2024 to 13-10-2024' },
    { week: 'Week 4', dateRange: '14-10-2024 to 20-10-2024' },
    { week: 'Week 5', dateRange: '21-10-2024 to 27-10-2024' },
    { week: 'Week 6', dateRange: '28-10-2024 to 31-10-2024' },
];

export default function TermsCondition({ dynamicCss }) {
    const [expanded, setExpanded] = React.useState(""); // Set "panel3" as the default expanded panel

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Box sx={{
                padding: "0 16px"
            }}>
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid #C1C1C133",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                        color: "#fff",
                        ...dynamicCss
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel1" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '600',
                            lineHeight: "18px",

                        }}
                    >
                        What is CashKaro Diwali League?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '400',
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        CashKaro Diwali League is a leaderboard contest where registered users compete by accumulating points through specific actions. Points are categorized into weekly and monthly totals, determining user rankings. Top 500 participants weekly and top 10 participants overall will receive Amazon vouchers based on their ranks.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel2" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Who can participate?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>The contest is open to registered users of CashKaro only . Participants must explicitly register for the Contest through the dedicated registration page.</li>
                            <li>Only one entry per person is allowed.</li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1.5px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel3" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Campaign duration
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '400',
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>The Contest will run from September 23, 2024, to October 31, 2024.</li>
                            <li>The leaderboard is divided into weekly and Overall rankings. See weeks and their date ranges in below table</li>
                            <ul>
                                <li>Weekly ranking is defined as the rank achieved during a week. Weeks are divided basis below dates:
                                    <TableContainer component={Paper} sx={{ margin: '0' }}>
                                        <Table aria-label="date range table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ border: '1px solid black', fontWeight: 'bold' }}>Week</TableCell>
                                                    <TableCell align="center" sx={{ border: '1px solid black', fontWeight: 'bold' }}>Date range</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dateData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="center" sx={{ border: '1px solid black' }}>{row.week}</TableCell>
                                                        <TableCell align="center" sx={{ border: '1px solid black' }}>{row.dateRange}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>




                                </li>
                                <li>Overall ranking is defined as the rank achieved during the entire campaign duration, which is between 23-09-2024 and 31-10-2024</li>
                            </ul>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel4" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        How do I get points?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <b>There are 6 different ways to get points</b>
                        <ul>
                            <li>Date considered:</li>
                            <ul>
                                <li>
                                    For all below activities Date for considering points addition would be the cashback tracked date as shown in the CashKaro application.
                                </li>
                            </ul>
                            <li>Discover Brands:
                                <ul>
                                    <li>Earn 10 points for every unique brand you visit via CashKaro from the list shared.
                                        <li>Get additional 30 points  on visiting at least 5 unique brands</li>
                                        <li>Get additional 60 points if you visit all the 10 brands</li>
                                    </li>
                                </ul>
                                <li>Earn points on every shopping via CashKaro:
                                    <ul>
                                        <li>For every shopping done on Amazon, Flipkart, AJIO and Myntra via CashKaro get 100 points for each transaction.</li>
                                        <li>
                                            Apply for any Credit Card via CashKaro and get 400 points. For each card you apply and get approved for.
                                        </li>
                                        <li>Shop from anywhere else and get 200 points for every transaction.</li>
                                        <li>
                                            No points for Swiggy, Recharges, Bill payments, Amazon Pay & brands with no Cashback.</li>
                                    </ul>
                                    <li>Shop from Today's Featured Brand:
                                        <ul>
                                            <li>Shop from the Brand of the Day and earn an additional 300 points.</li>
                                        </ul>
                                        <li>Achieve Milestone Points:
                                            <ul>
                                                <li>Unlock extra points by reaching specific point milestones.</li>
                                                <li>Earn up to 1,000 bonus points for achieving these milestone targets.</li>

                                            </ul>
                                            <li>Earn points for consecutive days of shopping:
                                                <ul>
                                                    <li>Shop for 2 days in a row: Get 200 bonus points</li>
                                                    <li>Shop for 3 days in a row: Get 300 additional bonus points</li>
                                                    <li>
                                                        Shop for 5 days in a row: Get 500 additional bonus points
                                                    </li>
                                                    <li>For example: If you shop from 7-10-2024  for 5 consecutive days (till 11-10-2024), you get 200 points for shopping on 7th and 8th , additional 300 points for shopping again on 9th, additional 500 bonus points for shopping on 10th and 11th .</li>
                                                    <li>If your streak overlaps between two weeks the points will be added to the week in which the streak ends. Example your Assume you have a streak of 2 days  starting on the 6th  and ending on the 7th . Your first tracked transaction (of 6th ) was in week1. And 2nd tracked transaction (7th) was in week 2. Then your bonus points for streak will be added in week 2.</li>
                                                    <li>If you shop for more than 5 days consecutively, your streak will start from 1 again. Example: If you shop for 7 consecutive days , from 7th to 13th you would have a 5 day streak (7th to 11th) and a 2 day streak (12th to 13th)</li>
                                                </ul>
                                                <li>Earn bonus points based on your daily spend:</li>
                                                <ul>
                                                    <li>Spend ₹5,000 in a day: Earn up to 100 points</li>
                                                    <li>Spend ₹7,500 in a day: Earn up to 150 points</li>
                                                    <li>Spend ₹10,000 in a day: Earn up to 200 points.</li>
                                                    <li>Spends on Swiggy, Amazon pay, recharges, bill payments and any brand with no cashback will not be counted</li>
                                                    <li>Daily spend will be calculated daily, at the end of day at 11:59 PM.</li>
                                                </ul>
                                            </li>
                                        </li>
                                    </li>
                                </li>
                            </li>
                            <li>Some information may not reflect on a real-time basis at your end, rest assured it's being calculated accurately by us.</li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel5" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="5-content"
                        id="5-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Rewards or Benefits
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li><b>Lucky Draw Prizes:</b> Upon registering for the Contest, participants are automatically entered into a raffle (lucky draw) for a chance to win a Royal Enfield hunter, iPhone 14 128 GB, or a 32-inch Smart Television.</li>
                            <li><b>Weekly Prizes:</b> Participants with top 500 ranks each week will receive Amazon vouchers based on the following breakdown:</li>
                            <ul>
                                <li>
                                    Ranks 1-10: ₹500 voucher each
                                </li>
                                <li>Ranks 11-50: ₹150 voucher each</li>
                                <li>Ranks 51-100: ₹100 voucher each</li>
                                <li>Ranks 101-200: ₹75 voucher each</li>
                                <li>Ranks 201-500: ₹50 voucher each
                                    Weeks are determined by below date ranges:
                                    <TableContainer component={Paper} sx={{ margin: '0' }}>
                                        <Table aria-label="date range table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ border: '1px solid black', fontWeight: 'bold' }}>Week</TableCell>
                                                    <TableCell align="center" sx={{ border: '1px solid black', fontWeight: 'bold' }}>Date range</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dateData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="center" sx={{ border: '1px solid black' }}>{row.week}</TableCell>
                                                        <TableCell align="center" sx={{ border: '1px solid black' }}>{row.dateRange}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </li>
                            </ul>
                            <li><b>Overall Prizes:</b> Participants with top 10 rank at end of the campaign will receive Amazon vouchers based on the following breakdown:
                                <ul>
                                    <li>Ranks 1-3: ₹10,000 voucher each</li>
                                    <li>Ranks 4-6: ₹5,000 voucher each</li>
                                    <li>Ranks 7-10: ₹2,500 voucher each</li>

                                </ul>
                            </li>
                            Participants will be eligible for lucky draw and overall campaign prizes only once. Participants are eligible to participate in weekly prizes every week.
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel6" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="6-content"
                        id="6-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Do I have to register for the contest?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>You must be a registered CashKaro user and then register for the Contest via the dedicated landing page.</li>
                            <li>Only activities post registration will be considered for points calculation and addition.</li>
                            <li>The date for considering points addition would be the cashback tracked date as shown in the CashKaro application.</li>
                        </ul>


                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel7" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="7-content"
                        id="7-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Exclusions and Limitations
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>Points can only be earned on brands offering cashback through the CashKaro app.</li>
                            <li>Participants can qualify for weekly rewards multiple times but can win only one of the raffle prizes and one Overall Prize.</li>
                        </ul>



                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel8"}
                    onChange={handleChange("panel8")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel8" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="8-content"
                        id="8-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        When do you get the prizes?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>Weekly Winners would be announced 2 days post closure of the week. For example, if a week ends on 29-09-2024, then the winner of that week would be announced two days later, on 01-10-2024.</li>
                            <li>Final winners and their ranks would be shared by 7th November 2024 with the winners via email.</li>
                            <li>Actual voucher distribution will happen by December 20, 2024. The vouchers will be sent to the winners' email IDs registered with CashKaro. We will not entertain any requests for sharing the voucher to a different email ID other than the CashKaro registered email ID of the winner.</li>
                            <li>If the cashback for a transaction is canceled, the final points and rank will be adjusted accordingly, and the appropriate voucher will be given according to the new rank.</li>
                            <li>Vouchers will be sent to the registered email addresses of the winners by December 20, 2024.</li>
                            <li>For raffle (Lucky draw) prizes:
                                <ul>
                                    <li>Bike, Royal Enfield hunter: The winner must collect the bike in Delhi. The user will have to pay taxes as applicable and bear any transportation costs to their residing city.</li>
                                    <li>Phone (iPhone 14) and TV (32-inch Smart TV): Prizes will be delivered to the winners' provided addresses, subject to serviceability of their pin code.</li>
                                    <li>There will be no cash reward shared instead of the product. If the winner does not want the product, they will forfeit their claim.</li>
                                </ul>
                            </li>
                        </ul></AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel9"}
                    onChange={handleChange("panel9")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel9" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="9-content"
                        id="9-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Timeline for Fulfilment
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>All weekly and overall vouchers will be distributed on December 20, 2024.</li>
                            <li>Raffle winners will be announced on December 20, 2024, on CashKaro’s official Instagram page. Follow our Instagram page <a href="https://www.instagram.com/cashkaro" target="_blank">here</a>.</li>
                            <li>Prizes must be claimed within one week of the announcement. Unclaimed prizes will be forfeited.</li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel10"}
                    onChange={handleChange("panel10")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel10" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="10-content"
                        id="10-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Adjustments and termination
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>CashKaro reserves the right to modify or terminate the Contest at any time without prior notice.</li>
                            <li>In the event of discrepancies, including transaction cancellations or fraudulent activities, CashKaro reserves the right to adjust scores and rankings.</li>
                            <li>No upward adjustments in rankings will occur due to another participant's score adjustment; rankings may only decrease.</li>
                        </ul>


                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel11"}
                    onChange={handleChange("panel11")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel11" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="11-content"
                        id="11-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        When is the leader board updated?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>The leaderboard is updated daily at 9:00 AM, reflecting tracked transactions from the previous day.</li>
                            <li>Rankings are determined by the total points accumulated during the Contest period.</li>
                            <li>In case of a tie, the total order value of transactions will be used as a tiebreaker. If there is still a tie, the total number of transactions will be used as a tiebreaker.</li>
                            <li>Participants can view both the weekly and overall leaderboard.</li>
                        </ul>



                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel12"}
                    onChange={handleChange("panel12")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel12" ? <RemoveIcon sx={{ color: '#fff' }} /> : <AddIcon sx={{ color: '#fff' }} />}
                        aria-controls="12-content"
                        id="12-header"
                        sx={{
                            color: "#fff",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Declaration by user
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#fff",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Gurugram, Haryana.</li>
                            <li>By participating in the CashKaro Diwali League, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</li>
                        </ul>




                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
}
