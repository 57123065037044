import { Box, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide1 from '../../images/diwali/win23.png';
import Win23 from '../../images/diwali/winner.png';
import Slide2 from '../../images/diwali/bike.png';
import Slide3 from '../../images/diwali/fryer.png';
import Win22 from '../../images/diwali/winner-2022.png';
import Slide4 from '../../images/diwali/phone.png'


// Import Swiper styles
import "swiper/css";

const PastWinner = () => {
    return (
        <>
            <Box>
                <Swiper
                    spaceBetween={16}
                    slidesPerView={1.3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    style={{ paddingTop: '27px', paddingLeft: '1rem', paddingBottom: '42px' }}
                >

                    <SwiperSlide>
                        <Box
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                width: "100%",
                                height: "164px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1px",
                                position: "relative",
                                paddingTop: "24px",
                            }}
                        >
                            {" "}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "-1px",
                                    left: "0",
                                    right: "0",
                                    margin: "0 auto",
                                    display: "flex",
                                    justifyContent:"center"
                                }}
                            >
                                <img src={Win23} alt="win23" style={{ width: '124px' }} />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "4px",
                                    paddingLeft: '10px'
                                }}
                            >
                                <img src={Slide1} alt="slide1" style={{ width: '116px' }} />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: '"Montserrat Alternates"',
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: '700',
                                        lineHeight: "normal",
                                        marginBottom: "4px",
                                    }}
                                >
                                    Komal <br />
                                    Sharma
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: '500',
                                        lineHeight: "150%",
                                        marginBottom: "8px",
                                        paddingRight: '13px'
                                    }}
                                >
                                    Won a <b>Wireless Speaker</b> worth
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "20px",
                                        fontWeight: '700',
                                        lineHeight: "130%",
                                    }}
                                >
                                    ₹10,000
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                width: "100%",
                                height: "164px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1px",
                                position: "relative",
                                paddingTop: "24px",
                            }}
                        >
                            {" "}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "-1px",
                                    left: "0",
                                    right: "0",
                                    margin: "0 auto",
                                    display: "inline-table",
                                }}
                            >
                                <img src={Win23} alt="win23" style={{ width: '124px' }} />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "4px",
                                    paddingLeft: '10px'
                                }}
                            >
                                <img src={Slide2} alt="slide2" style={{ width: '116px' }} />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: '"Montserrat Alternates"',
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: '700',
                                        lineHeight: "normal",
                                        marginBottom: "4px",
                                    }}
                                >
                                    Robin<br /> Kumar
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: '500',
                                        lineHeight: "150%",
                                        marginBottom: "8px",
                                        paddingRight: '13px'
                                    }}
                                >
                                    Won a <b>Bike</b> worth
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "20px",
                                        fontWeight: '700',
                                        lineHeight: "130%",
                                    }}
                                >
                                    ₹95,000
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                width: "100%",
                                height: "164px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1px",
                                position: "relative",
                                paddingTop: "24px",
                            }}
                        >
                            {" "}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "-1px",
                                    left: "0",
                                    right: "0",
                                    margin: "0 auto",
                                    display: "inline-table",
                                }}
                            >
                                <img src={Win22} alt="win23" style={{ width: '124px' }} />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "4px",
                                    paddingLeft: '10px'
                                }}
                            >
                                <img src={Slide3} alt="slide3" style={{ width: '116px' }} />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: '"Montserrat Alternates"',
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: '700',
                                        lineHeight: "normal",
                                        marginBottom: "4px",
                                    }}
                                >
                                    Sakshi <br />
                                    Mehra
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "150%",
                                        marginBottom: "8px",
                                        maxWidth: "116px",
                                    }}
                                >
                                    Won a <b>Air</b> <b>fryer</b> worth
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        lineHeight: "130%",
                                        fontWeight: '700'
                                    }}
                                >
                                    ₹12,000
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                width: "100%",
                                height: "164px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1px",
                                position: "relative",
                                paddingTop: "24px",
                            }}
                        >
                            {" "}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "-1px",
                                    left: "0",
                                    right: "0",
                                    margin: "0 auto",
                                    display: "inline-table",
                                }}
                            >
                                <img src={Win22} alt="win23" style={{ width: '124px' }} />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "4px",
                                    paddingLeft: '10px'
                                }}
                            >
                                <img src={Slide4} alt="slide4" style={{ width: '116px', }} />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: '"Montserrat Alternates"',
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: '700',
                                        lineHeight: "normal",
                                        marginBottom: "4px",
                                    }}
                                >
                                    Sakshi <br />
                                    Mehra
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "150%",
                                        marginBottom: "8px",
                                        maxWidth: "116px",
                                    }}
                                >
                                    Won a <b>Phone</b>
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#033F63",
                                        fontFamily: "Metropolis",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        lineHeight: "130%",
                                        fontWeight: '700'
                                    }}
                                >
                                    ₹1,00,000
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
        </>
    );
};

export default PastWinner;
