import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Box, Typography, Button } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "../../images/diwali/Animation - 1726496643142.json";
import BG from '../../images/diwali/congrats-bg.png'; // Replace this with your background image path
import { useNavigate } from "react-router-dom";
import RepeatUser from '../../images/diwali/repeat-user.png';

export default function CongratulationsPopup() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(true); // Set to true to show the popup initially
    const [isStopped, setIsStopped] = useState(false); // Control Lottie animation stop
    const [isPaused, setIsPaused] = useState(false); // Control Lottie animation pause

    const handleClose = () => {
        setOpen(false);
        setIsStopped(true); // Stop the animation when the popup is closed
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            //   setOpen(false);
            setIsStopped(true); // Stop the animation after 5 seconds
            navigate("/diwali-league/index",{replace:true})
        }, 5000); // Auto close after 5 seconds

        return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted early
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen // Makes the dialog take up the full width and height of the screen
            PaperProps={{
                sx: {
                    borderRadius: 0, // Remove border radius for full screen
                    backgroundImage: `url(${BG})`,
                    backgroundSize: "cover", // Ensures the image covers the entire container
                    backgroundPosition: "center", // Center the image
                    backgroundRepeat: "no-repeat", // Prevents image repetition
                    width: "100%",
                    boxShadow: "none",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    zIndex: "99999999999999",
                },
            }}
        >
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "2rem",
                }}
            >
                <Box sx={{ position: "relative", zIndex: 2 }}>
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontFamily: '"Montserrat Alternates"',
                                fontSize: "27px",
                                fontStyle: "normal",
                                fontWeight: 900,
                                lineHeight: "120%",
                                background:
                                    "linear-gradient(138deg, #D886FF -122.33%, #4B0095 187.98%)",
                                backgroundClip: "text",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                marginBottom: "16px",
                            }}
                        >
                            Congratulations
                        </Typography>
                        <Typography sx={{ fontSize: "27px", marginTop: "-5%" }}>
                            🎉
                        </Typography>
                    </div>
                    <Typography
                        sx={{
                            color: "#572588",
                            textAlign: "center",
                            fontFamily: "Metropolis",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "130%",
                        }}
                    >
                        You're now officially registered for the lucky draw.
                    </Typography>
                </Box>
                <Lottie
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1, // Make sure it's behind the text
                    }}
                    options={defaultOptions}
                    height={`100%`}
                    width={`100%`}
                    isStopped={isStopped}
                    isPaused={isPaused}
                />
            </DialogContent>
        </Dialog>
    );
}
