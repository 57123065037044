import React, { useState } from "react";
import { Grid, Typography, Box, Slider, Button } from "@mui/material";
import { styled } from "@mui/material";
import maintainshopping from "../../images/diwali/maintainshopping.png";
import thumbimg from "../../images/diwali/thumbimg.png";
// import maintainshoppingimg from "../../images/diwali/maintainshoppingimg.png";
import point200 from "../../images/diwali/point200.png";
import point200active from "../../images/diwali/point200active.png";
import point300 from "../../images/diwali/point300.png";
import point300active from "../../images/diwali/point300active.png";
import point500 from "../../images/diwali/point500.png";
// import point500active from "../../images/diwali/point500active.png";
import win200 from "../../images/diwali/win200.png";
import win300 from "../../images/diwali/win300.png";
import win500 from "../../images/diwali/win500.png";
import ButtonComponent from "../../components/diwali/buttonBottom";


const CustomSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-mark.MuiSlider-markActive:first-child": {
    background: "transparent",
  },
}));

const images = {
  2: point200active,
  3: point300active,
  5: point300active,
};

const marks = [
  { value: 0,  },
  { value: 2,},
  { value: 3,  },
  { value: 5, },
];
export default function MaintainShopping() {
  const [sliderValue, setSliderValue] = useState(localStorage.getItem("diwali_current_streak") || 0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ background: "#1F162E" }}>
        <Grid
          sx={{
            background: "#1F162E",
            width: "100%",
          }}
        >
          <Grid pb={2}>
            <img src={maintainshopping} style={{ width: "100%" }} />
          </Grid>
          <Grid item sx={12} pr={3} pl={3} pb={2}>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "140%",
                textTransform: "capitalize",
                marginBottom: "8px",
              }}
            >
              Maintain Shopping Streak
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                color: "#fff",
              }}
            >
              Shop daily for 2 days or more to create a streak. Maintain your
              streak and get up to 1000 points
            </Typography>
          </Grid>

          <Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0 0 16px",
                gap:"0",
                maxWidth:"350px",
                margin:"0 auto"
              }}
            >
              <Box
              sx={{
                position:"relative",
                left:"30px",
                width:"33.3%",
                minWidth:"138px",
              }}
              >
                <img
                  src={sliderValue >= 2 ? images[2] : point200}
                  style={{ width: "100%" }}
                />

                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 2
                </Typography>
              </Box>
              <Box
              sx={{
                position:"relative",
                width:"33.3%",
                minWidth:"138px",
              }}
             
              >
                <img
                  src={sliderValue >= 3 ? images[3] : point300}
                  style={{ width: "100%" }}
                />
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 3
                </Typography>
              </Box>
              <Box
              sx={{
                position:"relative",
                right:"30px",
                width:"33.3%",
                minWidth:"138px",
              }}
              >
                <img
                  src={sliderValue >= 5 ? images[5] : point500}
                  style={{ width: "100%" }}
                />
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 5
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "0 40px",
                position: "relative",
              }}
            >
       <CustomSlider
    aria-label="Temperature"
    defaultValue={0}
    value={sliderValue}
    onChange={handleSliderChange}
    valueLabelDisplay="on"
    valueLabelFormat={(value) => (value === 0 ? "No streak yet" : `You are at day ${value}`)}
    step={1}
    marks={marks}
    disabled
    min={0}
    max={5}
    sx={{
        color: "#E85F34", // Change the color here
        padding: "4px !important",
        boxShadow: "0px 0px 0px",
        borderRadius: "20px",
        zIndex: "222",
        fontFamily:'Metropolis',
        position: "relative",
        height: 6,
        '& .MuiSlider-valueLabel': {
            bgcolor: 'transparent', // Background color of the label
            color: 'white', // Text color of the label
            borderRadius: 1, // Rounded corners
            padding: '4px 8px', // Padding around the label
            fontSize: "8px",
            position: "absolute",
            top: "calc(100% + 16px)", // Adjust to place tooltip below
            left: "-65%",
            transform: "translateX(-50%)", // Center tooltip horizontally
        },
        '& .MuiTooltip-tooltip': {
          
          bgcolor: 'transparent', // Background color of the label
          color: 'white', // Text color of the label
          borderRadius: 1, // Rounded corners
          padding: '4px 8px', // Padding around the label
          fontSize: "8px",
          position: "absolute",
           top: "calc(100% + 16px)",
          left: "50%",
          transform: "translateX(-50%)", // Center tooltip horizontally
        },
        '&.Mui-disabled': {
            color: '#E85F34', // Custom color for disabled state
        },
        "& .MuiSlider-thumb": {
            height: 26,
            width: 37,
            background: `url(${thumbimg})`, // Change thumb image
            "&:focus, &:hover, &.Mui-active": {
                boxShadow: "inherit",
            },
            ":before": {
                boxShadow: "0px 0px 0px",
            },
        },
        "& .MuiSlider-track": {
            height: 6, // Change track height
        },
        "& .MuiSlider-rail": {
            height: 6, // Change rail height
            backgroundColor: "#36276A", // Rail color
            opacity: "1",
        },
        "& .MuiSlider-mark": {
            backgroundColor: "#36276A", // Mark color
            height: 12, // Mark height
            width: 2, // Mark width
            borderRadius: "20px",
            '&.MuiSlider-markActive': {
                backgroundColor: 'transparent', // Active mark color
            },
        },
        "& .MuiSlider-markLabel": {
            color: "#fff",
            fontSize: "8px",
            top: "24px !important",
        },
        "& .MuiSlider-markLabel:first-of-type": {
            marginLeft: "8px",
        },
        "& .MuiSlider-markLabel:last-of-type": {
            marginRight: "8px",
        },
    }}
/>


              {/* <Box
                sx={{
                  width: "60.314px",
                  height: "8px",
                  flexShrink: 0,
                  borderRadius: "36.188px",
                  background: "#371D0C",
                  filter: "blur(2.507853388786316px)",
                  margin: "0 auto",
                  left: "0",
                  right: "0",
                  bottom: "11px",
                  position: "absolute",
                  zIndex: "1",
                }}
              ></Box> */}
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.50)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  paddingBottom: "40px",
                  paddingTop: "32px",
                }}
              >
                *Last updated on {localStorage.getItem('formated_date')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: "129px", }} sx={12}>
          {/* <img src={maintainshoppingimg} style={{ width: "100%" }} /> */}

      <Grid style={{ display:"flex", padding:"7px 34px 7px 0", marginBottom:"-36px", alignItems:"center"}}>
      <Grid sx={{
               width:"40%"
          }}>
            <img src={win200} style={{ width:"100%"}} />
          </Grid>
          <Grid sx={{
             width:"60%",
               position:"relative",
             left:"-10px"
          }}>
            <Typography
              sx={{
                color: "#FFD833",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
              Win 200 Points
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              Shop daily for 2 days & get 200 points
            </Typography>
          </Grid>
      </Grid>
      <Grid style={{ display:"flex", padding:"7px 34px 7px 0", marginBottom:"-36px", alignItems:"center"}}>
      <Grid sx={{
               width:"40%"
          }}>
            <img src={win300} style={{ width:"100%"}} />
          </Grid>
          <Grid sx={{
             width:"60%",
               position:"relative",
             left:"-10px"
          }}>
            <Typography
              sx={{
                color: "#FFD833",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
           Win 500 Points
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
             Shop daily for 3 days & get +300 additional points
            </Typography>
          </Grid>
      </Grid>
      <Grid style={{ display:"flex", padding:"7px 34px 7px 0",  marginBottom:"-36px", alignItems:"center"}}>
      <Grid sx={{
               width:"40%"
          }}>
            <img src={win500} style={{ width:"100%"}} />
          </Grid>
          <Grid sx={{
             width:"60%",
             position:"relative",
             left:"-10px"
          }}>
            <Typography
              sx={{
                color: "#FFD833",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
            Win 1000 Points
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Metropolis",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
           Shop daily for 5 days & get +500 additional points
            </Typography>
          </Grid>
      </Grid>

        </Grid>
        <a href='cashkaro://home'><ButtonComponent title="Start Your Streak"/></a>
        
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>{" "}
    </Grid>
  );
}
