import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Trophy from '../../images/diwali/trophy.png';

const PrizeClaimedTable = ({ week, rank, points, prize }) => (
    <Box sx={{ mb: 2}}>
        <Typography variant="h6" align="center" sx={{ mb: 1, fontWeight: '700',fontSize:'14px', fontFamily:'Metropolis' }}>
            Week {week}
        </Typography>
        <TableContainer component={Paper} sx={{ borderRadius: '12px', border:"1px solid #000", boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)' }}>
            <Table sx={{ minWidth: 250 }} aria-label="prize table">
                <TableHead style={{border:"0"}}>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: '600', borderRight:"1px solid #000", borderBottom:"1px solid #000", fontSize:'12px',fontFamily:'Metropolis', padding:"12px 24px 12px", lineHeight:"1" }}>Rank</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '600',  borderRight:"1px solid #000", borderBottom:"1px solid #000",  fontSize:'12px',fontFamily:'Metropolis', padding:"12px 24px 10px", lineHeight:"1" }}>Points</TableCell>
                        <TableCell align="center" sx={{ fontWeight: '600',fontSize:'10px',fontFamily:'Metropolis',borderBottom:"1px solid #000",  padding:"10px 20px 10px", lineHeight:"1" }}>Winning<br/><span style={{fontSize:'10px',fontWeight:'400'}}>(Amazon Voucher)</span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={{ fontSize: '14px',fontWeight:'600', borderRight:"1px solid #000",  fontFamily:'Metropolis', padding:"10px 24px 10px", lineHeight:"1" }}>{rank}</TableCell>
                        <TableCell align="center" sx={{ fontSize: '14px',fontWeight:'600', borderRight:"1px solid #000",  fontFamily:'Metropolis' , padding:"10px 24px 10px", lineHeight:"1"}}>{points}</TableCell>
                        <TableCell align="center" sx={{ fontSize: '14px',fontWeight:'600', fontFamily:'Metropolis', padding:"10px 24px 10px", borderBottom:"0", lineHeight:"1" }}>{prize}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
);


export default function PrizesClaimed() {
    const [prizes, setPrizes] = useState([
        { week: 4, rank: 4, points: 10000, prize: 10000 },
        { week: 3, rank: 4, points: 10000, prize: 10000 },
        { week: 2, rank: 4, points: 10000, prize: 10000 },
    ]);

    const [week1data,setWeek1Data] = useState({})
    const [week1Voucher,setWeek1Voucher] = useState(0)

    const [week2data,setWeek2Data] = useState({})
    const [week2Voucher,setWeek2Voucher] = useState(0)


    const updatePrize = () => {
        setPrizes((prevPrizes) => {
            const updatedPrizes = [...prevPrizes];
            updatedPrizes[0] = { ...updatedPrizes[0], prize: 15000 }; // Update the prize for week 4
            return updatedPrizes;
        });
    };

    const fetchWeek1Leaderboard = async () => {
        const ck_id = localStorage.getItem('diwali_ck_id')
        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/getleaderboard_cdl_week?week=1&ck_id=${ck_id}`)
            const data = await response.json()
    
            console.log('====>', data?.user_data?.user?.avatar)
    
            // Get the user's rank
            const userRank = data?.user_data?.user?.leaderboards_diwali_snapshots[0]?.rank;
    
            // Determine the voucher based on rank
            const determineVoucher = (rank) => {
                if (rank >= 1 && rank <= 10) {
                    return 500;
                } else if (rank >= 11 && rank <= 50) {
                    return 150;
                } else if (rank >= 51 && rank <= 100) {
                    return 100;
                } else if (rank >= 101 && rank <= 200) {
                    return 75;
                } else if (rank >= 201 && rank <= 500) {
                    return 50;
                } else {
                    return "-"; // No voucher if rank is outside these ranges
                }
            };
    
            // Set the voucher based on the user's rank
            const voucher = determineVoucher(userRank);
            setWeek1Voucher(voucher);
    
            data.rows.unshift({
                name: "YOU",
                rank: userRank,
                points: data?.user_data?.user?.leaderboards_diwali_snapshots[0]?.points,
                users_diwali: { avatar: data?.user_data?.user?.avatar },
                highlight: true
            })
    
            setWeek1Data(data?.user_data?.user?.leaderboards_diwali_snapshots[0])
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    const fetchWeek2Leaderboard = async () => {
        const ck_id = localStorage.getItem('diwali_ck_id')
        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/getleaderboard_cdl_week?week=2&ck_id=${ck_id}`)
            const data = await response.json()
    
            console.log('====>', data?.user_data?.user?.avatar)
    
            // Get the user's rank
            const userRank = data?.user_data?.user?.leaderboards_diwali_snapshots[0]?.rank;
    
            // Determine the voucher based on rank
            const determineVoucher = (rank) => {
                if (rank >= 1 && rank <= 10) {
                    return 500;
                } else if (rank >= 11 && rank <= 50) {
                    return 150;
                } else if (rank >= 51 && rank <= 100) {
                    return 100;
                } else if (rank >= 101 && rank <= 200) {
                    return 75;
                } else if (rank >= 201 && rank <= 500) {
                    return 50;
                } else {
                    return "-"; // No voucher if rank is outside these ranges
                }
            };
    
            // Set the voucher based on the user's rank
            const voucher = determineVoucher(userRank);
            setWeek2Voucher(voucher);
    
            data.rows.unshift({
                name: "YOU",
                rank: userRank,
                points: data?.user_data?.user?.leaderboards_diwali_snapshots[0]?.points,
                users_diwali: { avatar: data?.user_data?.user?.avatar },
                highlight: true
            })
    
            setWeek2Data(data?.user_data?.user?.leaderboards_diwali_snapshots[0])

            // console.log('=>****',data)
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };
    

    useEffect(()=>{
        fetchWeek1Leaderboard()
        fetchWeek2Leaderboard()
    },[])



    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: '1',
                        backgroundColor: "#fff",
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                        p: 3,
                        textAlign: 'center',
                        height: "70vh",
                        overflowY: 'auto',
                        zIndex:"999999"
                    }}
                >
                    <Box component="img" src={Trophy} alt="Trophy" sx={{ width: '100px', margin: '0 auto' }} />
                    <Typography variant="h5" sx={{ mt: 2, mb: 3, fontWeight: '700',fontSize:'14px',color:'#262626',fontFamily:'Metropolis' }}>
                        Prizes Claimed:
                    </Typography>
                    {/* {prizes.map((prize, index) => (
                        <PrizeClaimedTable
                            key={index}
                            week={prize.week}
                            rank={prize.rank}
                            points={prize.points}
                            prize={prize.prize}
                        />
                    ))} */}

<PrizeClaimedTable
                       
                            week={1}
                            rank={week1data?.rank || '-'}
                            points={week1data?.points || '-'}
                            prize={week1Voucher <= 500 ? ` ₹${week1Voucher}` : `-`} 
                        />

<PrizeClaimedTable
                       
                       week={2}
                       rank={week2data?.rank || '-'}
                       points={week2data?.points || '-'}
                       prize={week2Voucher <= 500 ? ` ₹${week2Voucher}` : `-`} 
                   />

                </Box>
            </Grid>
        </Grid>
    );
}
