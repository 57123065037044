import React, { useRef } from 'react';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, Box, FormHelperText, TextField, Stack, Divider, Alert, AlertTitle } from '@mui/material';
// import Topbanner from '../../images/main-banner.png';
import background from '../../images/diwali/about-prize.png';
import Terms from '../../components/diwali/t&cIndex';
import TC from '../../images/diwali/t&cs.png';
import ButtonComponent from '../../components/diwali/buttonBottom';

import Avator from '../../images/diwali/avator.png';
import Button from '@mui/material/Button';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';



export default function Signup() {


    const navigate = useNavigate();


    const [openPopup, setOpenPopup] = useState(false);

    const handleButtonClick = () => {
        setOpenPopup(true); // Open the popup when the button is clicked
    };

    const handleClosePopup = () => {
        setOpenPopup(false); // Close the popup
    };


    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid item xs={12}>
                <img src={background} alt='background' style={{ width: '100%',}} />
                    

                </Grid>
<Grid item xs={12} sx={{background: 'radial-gradient(224.81% 100% at 50% 0%, #7841C8 0%, #5611BA 100%)',paddingBottom:'30%'}}>
    <img src={TC} alt='Tc' style={{width:'100%',paddingLeft:'44px',paddingRight:"44px",paddingTop:'32px', paddingBottom:'32px'}}/>
<Terms />
</Grid>
<Grid container sx={{ position: 'relative', zIndex: '99' }}>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                background: '#411e7d',
                paddingY: '16px',
            }}>
                <Box sx={{ marginX: '1rem' }}>
                    <Button
                        variant="contained"
                        onClick={()=>navigate('/diwali-league/index',{replace:true})}
                        sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '12px',
                            color: '#0F0A1A',
                            padding: '16px',
                            fontWeight: '600',
                            fontSize: '14px',
                            fontFamily: 'Metropolis',
                            textTransform: 'none',
                            boxShadow: '0px 10px 8px -10px #FFFFFF, 0px -10px 7px -10px #FFFFFF',
                            '&:hover': {
                                background: 'rgba(255, 255, 255, 0.80)',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        Back to Leaderboard
                    </Button>
                </Box>
            </Grid>
        </Grid>
            </Grid>


            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};